import React, { useState } from "react";
import "../styling/Header.css";

function Header() {
  const [isNavVisible, setNavVisible] = useState(false);

  const toggleNav = () => {
    setNavVisible(!isNavVisible);
  };

  return (
    <header className="header">
      <div className="headergrid">
        <a href="/" className="HeaderLogo">
          <h1>Precise Hosting</h1>
        </a>
        <div className="hamburger-menu" onClick={toggleNav}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      <div className="header-nav-mobile">
        <nav className={`nav-menu ${isNavVisible ? "active" : ""}`}>
          <a href="/status" className="header-button">
            Status
          </a>
          <a href="https://discord.gg/bFSh2mBPzw" className="header-button">
            Discord
          </a>
          {/* <div className="header-nav">
            <a href="/products" className="header-button">
              Products
            </a>
            <div className="dropdown-content">
              <a href="/">Bot hosting</a>
              <a href="/products/Custom-Bot">Custom Bots</a>
            </div>
          </div> */}
          <a
            href="http://server.precisehosting.nl"
            className="header-button login-button"
          >
            Login
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
