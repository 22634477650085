import PricingCard from "./PricingCard";
import "../styling/DiscordPackages.css";

function DicsordPackages() {
  return (
    <div className="PackageContainer">
      <PricingCard
        title="DISCORD BOT 64MB"
        processor="4.0GHz"
        ram="DDR4 @ 3200MHz"
        storage="256MB NVMe SSD"
        speed="1000Mb/s"
        price="1 month trial"
        databasecount="1"
        backups={0}
      />
      <PricingCard
        title="DISCORD BOT 128MB"
        processor="4.0GHz"
        ram="DDR4 @ 3200MHz"
        storage="512MB NVMe SSD"
        speed="1000Mb/s"
        price="€0.79"
        databasecount="2"
        backups={1}
      />
      <PricingCard
        title="DISCORD BOT 256MB"
        processor="4.0GHz"
        ram="DDR4 @ 3200MHz"
        storage="1024MB NVMe SSD"
        speed="1000Mb/s"
        price="€1.39"
        databasecount="3"
        backups={2}
      />
      <PricingCard
        title="DISCORD BOT 512MB"
        processor="4.0GHz"
        ram="DDR4 @ 3200MHz"
        storage="2048MB NVMe SSD"
        speed="1000Mb/s"
        price="€1.99"
        databasecount="5"
        backups={4}
      />
    </div>
  );
}

export default DicsordPackages;
