import React from "react";
import "../styling/Footer.css"; // Zorg ervoor dat je het CSS-bestand importeert

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <h2>Precise Hosting</h2>
          <p>
            © {new Date().getFullYear()} Precise Hosting. All rights reserved.
          </p>
        </div>
        <div className="footer-right">
          {/* <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
          <div className="social-media">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
