import React from "react";
import "../styling/PricingCard.css";

const PricingCard = ({
  title,
  processor,
  ram,
  storage,
  speed,
  price,
  databasecount,
  backups,
}) => {
  return (
    <div className="pricing-card">
      <h2>{title}</h2>
      <p>{processor}</p>
      <p>{ram}</p>
      <p>{storage}</p>
      <p>{speed}</p>
      <ul>
        <li>DDos Protection</li>
        <li>{databasecount} FREE Database</li>
        <li>NPM auto-installer</li>
        {backups > 0 && <li>{backups} FREE backup</li>}
        {backups === 0 && <li>No backups</li>}
      </ul>
      <div className="price">
        {price === "1 month trial" && <span>{price}</span>}
        {price !== "1 month trial" && (
          <div>
            <span>{price}</span>
            <small>/ per month</small>
          </div>
        )}
      </div>
      <a href="https://discord.gg/bFSh2mBPzw">ORDER</a>
    </div>
  );
};

export default PricingCard;
