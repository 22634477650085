// StatusPage.js

import React from "react";
import "../styling/four0four.css";

const StatusPage = () => {
  return (
    <div className="status-container">
      <h1>Service Status</h1>
      <iframe src="https://beprecise.betteruptime.com" title="Service Status" />
    </div>
  );
};

export default StatusPage;
