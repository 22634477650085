import React from "react";
import HeroBannerIMG from "../assets/WebP/Hero.webp";
import "../styling/HeroBanner.css";

function HeroBanner() {
  return (
    <div className="backgroundhero">
      <div className="HeroContainer">
        <img src={HeroBannerIMG} alt="Hero Banner" className="HeroBanner" />
        <div id="BannerColor">
          <div className="HeroContent">
            <h1>Discord Bot Hosting</h1>
            <h2>MORE THEN JUST A HOSTING!</h2>
            <p>
              Upload your code and start hosting instantly! Our easy
              auto-installer gets your Discord bot online in no time.
            </p>
            <ul>
              <li>
                ✅ 4 GHz Processing Power: Ensures fast and efficient
                performance.
              </li>
              <li>
                ✅ 24/7 Uptime: Keep your bot online and accessible around the
                clock
              </li>
              <li>
                ✅ DDR4 RAM 3200MHz: Provides optimal speed and responsiveness.
              </li>
              <li>
                ✅ NVMe SSD Storage: Offers rapid data transfer and reliable
                storage.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;
