import DicsordPackages from '../components/DiscordPackages';
import GamePanel from '../components/GamePaneel';
import HeroBanner from '../components/HeroBanner';

function Home() {
  return (
    <div>
          <HeroBanner />
          <DicsordPackages />
          <GamePanel />
    </div>
  );
}

export default Home;