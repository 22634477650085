import React from "react";
import "../styling/GamePaneel.css";
import GamePanelIMG from "../assets/WebP/Console.webp";

const GamePanel = () => {
  return (
    <div className="game-panel">
      <div className="left-section">
        <h2>User-Friendly Control Panel with Extensive Features</h2>
        <p>
          With our fast auto-installer, you’ll be up and running with minimal
          effort. Start hosting your bot within minutes!
        </p>
        <br />
        <ul className="options-list">
          <li>✅ User-Friendly Console: Navigate and manage with ease.</li>
          <li>✅ Statistics: Monitor performance and track activity.</li>
          <li>✅ File Management: Easily manage and organize your files.</li>
          <li>✅ Databases: Integrated database support for your bot.</li>
          <li>✅ Backups: Reliable backup system to safeguard your data.</li>
          <li>✅ Sub-Users: Manage permissions with multiple user accounts.</li>
          <li>
            ✅ Built-In Auto Installer: Seamlessly set up your bot with our
            integrated installation process.
          </li>
        </ul>
        <br />
        <p>
          Get Started Today! Experience hassle-free Discord bot hosting with our
          comprehensive control panel.
        </p>
      </div>
      <div className="right-section">
        <img
          className="right-section-img-gamepanel"
          src={GamePanelIMG}
          alt="GamePanel"
        />
        {/* <div className="header-buttons">
          <button className="start-btn">Start</button>
          <button className="restart-btn">Restart</button>
          <button className="stop-btn">Stop</button>
        </div>
        <div className="status-info">
          <div className="status-item">
            <h3>CPU USAGE</h3>
            <p>0.00%</p>
          </div>
          <div className="status-item">
            <h3>MEMORY USAGE</h3>
            <p>0.00%</p>
          </div>
          <div className="status-item">
            <h3>DISK USAGE</h3>
            <p>2.80%</p>
          </div>
        </div>
        <div className="console">
          <p>container@localhost:~ Server marked as offline...</p>
        </div> */}
      </div>
    </div>
  );
};

export default GamePanel;
