// NotFoundPage.js
import React from "react";
import "../styling/Status.css"; // Make sure to import your CSS file

const NotFoundPage = () => {
  return (
    <div className="four0four-container">
      <h1 className="notfoundh1">404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      {/* <a href="/">Go back to the homepage</a> */}
    </div>
  );
};

export default NotFoundPage;
