// src/Routes.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  loader,
} from "react-router-dom";
import Home from "./views/Home";
import Status from "./views/Status";
import NotFoundPage from "./components/404";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/status" element={<Status />} />
      <Route path="*" Component={NotFoundPage} />
    </Routes>
  );
};

export default AppRoutes;
